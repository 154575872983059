<template>
  <div class="terms">
    <h1>Terms of Use</h1>
    <p>
      {{ $store.state.razao_social }}, {{ $store.state.sociedade }},
      headquartered at {{ $store.state.address }}, and registered under CNPJ/MF
      number {{ $store.state.cnpj }}, hereinafter referred to as "MY BABY BOOK",
      owner of the website
      <a href="https://mybabybookapp.com">mybabybookapp.com</a>, hereby
      establishes the rules and conditions to be observed and strictly followed
      by the USER, who, once they have expressed their "AGREE" in the designated
      field at the end, will have entered into this instrument for the provision
      of services and other agreements, in accordance with the clauses that
      follow. However, if you, the USER, do not agree with the terms established
      herein, you should click "CANCEL" in the specific field at the end, which
      will prevent the formation of this instrument and, consequently, the
      provision of any services by MY BABY BOOK.
    </p>

    <h2>1. REQUIREMENTS FOR ACCESS TO PROVIDED SERVICES</h2>
    <p>
      1.1. By clicking "AGREE", you will be adhering to all the terms of this
      document and confirming the information provided when you filled out your
      personal registration. From that point on, you may use the services
      provided by the WEBSITE and APPLICATION, also referred to as "My Baby
      Book."
    </p>
    <p>
      1.1.1. Users under eighteen years old are only authorized to contract the
      SERVICES and PRODUCTS provided by "MY BABY BOOK" if effectively assisted
      or represented by their responsible parents, guardians, or legal
      representatives, and this information must be expressly stated in the
      filled-out registration.
    </p>
    <p>
      1.1.2. When filling out the registration, the USER will set the desired
      password. This password must consist of at least six alphanumeric
      characters.
    </p>
    <p>
      1.1.3. The USER is solely responsible for the information provided in the
      registration. Therefore, any attempt to create false identity, age, email
      address, or any other information will be considered a violation of the
      law and the provisions of this Terms of Use, leading to its automatic and
      immediate termination, without "MY BABY BOOK," its contractors, service
      providers, or partners being held responsible for compensation to the USER
      or third parties harmed by the false information provided.
    </p>

    <p>
      1.1.4. If falsehoods are identified in the allegations made by the USER to
      "MY BABY BOOK," it reserves the right to automatically terminate this
      agreement, without prejudice to any compensation due by the USER for the
      damages suffered. Furthermore, "MY BABY BOOK," its contractors, service
      providers, or partners may, at its sole discretion, contact the competent
      authorities regarding the incident and cooperate in every way, including
      disclosing content from the USER's PERSONAL AREA, for the investigation of
      damages and crimes committed.
    </p>

    <p>
      1.1.5. The statements below reproduced are considered as made by the
      USER(S):
    </p>

    <ol class="list-alpha">
      <li>
        I, as a USER, commit to using only images of my own authorship and those
        for which I have legitimate interest of use on the "MY BABY BOOK" SITE
        and APPLICATION. I hereby release the company responsible for the
        application, "MY BABY BOOK," its contractors, partners, or service
        providers from any liabilities related to this, as I understand that
        they are not obligated to question the origin of the images I will use
        in my album.
      </li>
      <li>
        I, as a USER, commit not to use this platform for illegal purposes or
        that violate the rights or intellectual property of third parties,
        including but not limited to rules related to trademarks and patents.
      </li>
      <li>
        I, as a USER, commit not to use images of third parties on this platform
        without their express authorization.
      </li>
      <li>
        I, as a USER, declare that I am at least 18 years old. Minors can only
        use the services and products provided by "MY BABY BOOK" under the
        supervision of an adult.
      </li>
      <li>
        I, as a USER, commit not to use "MY BABY BOOK" to store content that can
        be considered offensive, pornographic, obscene, threatening, or any
        other that may give rise to civil liability or be considered a crime or
        offense, including material that violates ethics, morals, and good
        customs or that may incite discrimination of any kind (racial, cultural,
        religious, sexual, or other).
      </li>
      <li>
        I, as a USER, commit to indemnify "MY BABY BOOK," its contractors,
        service providers, or partners, for any damages, whether material or
        moral, that they may suffer as a result of non-compliance with the
        provisions of this document, for any conviction in any sphere
        (administrative or judicial), arising from the irregular or unlawful use
        of the SERVICES and PRODUCTS provided by the SITE and APPLICATION. I
        also take responsibility for the costs and attorney's fees that may be
        incurred in defense of the interests of "MY BABY BOOK," its contractors,
        service providers, or partners. I also take responsibility for
        compensating third parties or "MY BABY BOOK" for damages caused by the
        use of the SERVICES or PRODUCTS provided here in violation of this Terms
        of Use.
      </li>
    </ol>

    <h2>2. OBJECT</h2>
    <p>
      2.1. The object of this instrument is the provision of services for the
      availability of space for the storage of images, videos, photos, texts, or
      digital files (IMAGES or FILES) in a specific and determined environment,
      accessible via the SITE and APPLICATION (referred to as PERSONAL AREA),
      for display to the USER and persons authorized by the USER (GUESTS),
      solely for the purpose of using such CONTENT to create a DIGITAL BABY
      MEMORY ALBUM.
    </p>
    <p>
      2.1.1. The images, videos, photos, texts, or digital files subject to the
      above item will be made available by the SITE and APPLICATION on a
      non-exclusive basis, and it is at the discretion of the USER to store them
      on other websites with the same or similar nature.
    </p>
    <p>
      2.1.2. The SITE and APPLICATION will provide a software for free download
      by the USER, compatible with Android or IOS platforms, enabling the
      storage of images, videos, and texts for the creation of the DIGITAL
      ALBUM. This album can be accessed through the APPLICATION or SITE.
    </p>
    <p>
      2.1.3. With the aim of improving the software's functionality or updating
      the services provided, "MY BABY BOOK" may, directly or through its
      partners, contractors, or service providers, make modifications to the
      installed system version on the USER's device (automatic update).
    </p>
    <p>
      2.1.4. Considering the possibility of communication system failures during
      downloading and the configuration of the USER's device, "MY BABY BOOK"
      does not guarantee the functionality of the said software, nor is it
      liable for its incorrect use or the consequences that may arise from its
      eventual use, including automatic updates.
    </p>
    <p>
      2.1.5. The disclosure and sale of third-party products or services through
      "MY BABY BOOK" or the USER's PERSONAL AREA is prohibited.
    </p>
    <p>
      2.1.6. "MY BABY BOOK" may be used by national and foreign individuals,
      subject to Brazilian legislation. Foreign individuals expressly authorize
      their IMAGES to be processed in Brazil.
    </p>

    <h2>3. UPLOADING AND ARCHIVING IMAGES IN THE USER'S PERSONAL AREA:</h2>
    <p>
      3.1. The USER will send images, videos, photos, texts, or digital files to
      "MY BABY BOOK" digitally (upload), with IMAGES, VIDEOS, and TEXTS received
      with a maximum size of 200 MB each.
    </p>
    <p>
      3.1.1. The quality of presentation of the IMAGES stored in "MY BABY BOOK"
      will depend on the resolution and quality (sharpness, focus, color
      balance, brightness, and contrast) of the FILE sent by the USER and will
      be their sole responsibility.
    </p>
    <p>
      3.1.2. "MY BABY BOOK," its contractors, partners, or service providers do
      not commit to or take responsibility for the storage, restoration,
      recovery, or return of received IMAGES. It is the USER's responsibility to
      store IMAGES, whether original or copies. The USER IS OBLIGATED TO
      MAINTAIN A BACKUP COPY OF ALL IMAGES AND/OR FILES SENT TO THE SITE and
      APPLICATION.
    </p>

    <h2>4. SECURITY</h2>
    <p>
      4.1. "MY BABY BOOK," in its efforts to maintain its system and ensure the
      privacy and security of its USERS' information, will adopt the best
      measures to prevent unauthorized third parties from accessing the USER's
      PERSONAL AREA.
    </p>
    <p>
      4.1.1. To achieve this, several security measures will be provided to the
      USER. When formalizing this agreement, the USER must create a personal
      password of at least 6 alphanumeric characters. This password will
      deactivate the security system developed and allow for viewing and
      potential editing of files and images stored in the USER's PERSONAL AREA.
    </p>
    <p>
      4.1.2. Once the password is registered, the USER can only access their
      PERSONAL AREA by providing the password. The USER must take care to keep
      their password confidential and ensure that third parties do not gain
      access to it.
    </p>
    <p>
      4.2. The password is personal and non-transferable. The USER is solely
      responsible for safeguarding and maintaining the password's
      confidentiality. Sharing the password with third parties will allow them
      to access the USER's PERSONAL AREA as if they were the USER.
    </p>
    <p>
      4.3. However, the USER can allow other individuals to access their
      PERSONAL AREA, in whole or in part, through the provision of a specific
      link. The USER will be responsible for the access granted by their GUESTS
      to the designated area(s).
    </p>

    <h2>5. PRIVACY</h2>
    <p>
      5.1. The information collected from the USER (name, age, email, phone
      number, date of birth, and country) will be used for the purposes of
      registration as defined in item I above and will be used for the purposes
      of this agreement.
    </p>
    <p>
      5.2. If the USER authorizes it, by selecting the appropriate option during
      the registration process, "MY BABY BOOK," its contractors, partners, or
      service providers may send the USER promotions and new information, such
      as information about new services and products.
    </p>
    <p>
      5.2.1. If the USER no longer wishes to receive such information, they can
      change the authorization option in the personal data editing area. In
      addition, "MY BABY BOOK," its contractors, partners, or service providers
      will only use the USER's email to confirm order status, provide support,
      or provide useful information. Additional information may be requested
      from the USER by "MY BABY BOOK" for the provision of such additional
      services or products.
    </p>
    <p>
      5.2.2. "MY BABY BOOK" respects your
      <router-link :to="to('/privacy')">privacy</router-link> and is against
      spam. Also, it does not sell or distribute your registration to third
      parties as part of our consumer respect policy. If you do not wish to
      receive the emails referred to in the above item, request opt-out by
      clicking the link at the end of each email or update your account
      preferences.
    </p>
    <p>
      5.3. For statistical purposes, "MY BABY BOOK," its contractors, partners,
      or service providers may use the information provided by the USER through
      their registration, as well as the manner, purpose, and times of use of
      the services, and any ads presented, when applicable. However, the USER's
      identity, email, or any other specific information will not be identified.
    </p>
    <p>
      5.4. "MY BABY BOOK" will not edit or modify the content of the USER's
      PERSONAL AREA.
    </p>
    <p>
      5.4.1. The USER agrees that if "MY BABY BOOK," its contractors, partners,
      or service providers encounter IMAGES or FILES during the aforementioned
      periodic checks whose content is, at its sole discretion, considered
      offensive, pornographic (involving minors or not), obscene, threatening,
      or contains material that may give rise to civil liability or that is
      considered a crime or misdemeanor, or violates the rule established in
      item 2.2., said IMAGES may be deleted from the platform's database.
      Furthermore, the agreement may be terminated without any form of
      compensation to the USER.
    </p>
    <p>
      5.5. "MY BABY BOOK" agrees to notify the USER if it discovers that the
      privacy of their PERSONAL AREA has been violated.
    </p>
    <p>
      5.6. The USER agrees not to access the PERSONAL AREA of any other USER
      through any potential system failure that may allow unauthorized access.
      The USER also agrees to immediately notify "MY BABY BOOK" of any security
      breach or possible breach in the platform's security that they become
      aware of.
    </p>
    <p>
      5.7. The USER is obliged to keep their registration data up to date so
      that "MY BABY BOOK," its contractors, partners, or service providers can
      contact them.
    </p>

    <h2>6. PUBLIC ACCESS</h2>
    <p>
      6.1. The USER's PERSONAL AREA will group images, videos, photos, texts, or
      digital files that may be divided and organized into ALBUMS.
    </p>
    <p>
      6.1.1. The ALBUMS, the contents of which are the sole and exclusive
      responsibility of the USER, may be shared via link.
    </p>
    <p>
      6.1.2. It is the USER's responsibility to select the people with whom they
      wish to share the content of their albums, and "MY BABY BOOK" is exempt
      from any issues arising from this sharing.
    </p>
    <h2>7. DURATION</h2>
    <p>
      7.1. This contract will last indefinitely or during the period in which
      "MY BABY BOOK" is providing its services to the USER.
    </p>

    <h2>8. CANCELLATION OR TERMINATION</h2>
    <p>
      8.1. The USER may cancel the services provided by "MY BABY BOOK" according
      to the terms defined at the time of their subscription, through a written
      communication made via email to the registered USER's email address or
      through access to "MY BABY BOOK," under the following terms:
    </p>
    <ol class="list-alpha">
      <li>
        The user may cancel the services within 7 (seven) days after the
        subscription of the service, in accordance with the Consumer Protection
        Code;
      </li>
      <li>
        For cancellations requested after 7 days, the services will only cease
        at the end of the current cycle at the time of cancellation.
      </li>
    </ol>

    <p>
      8.2. The services may be terminated by "MY BABY BOOK" under the following
      cases:
    </p>
    <ol class="list-alpha">
      <li>
        Due to proven non-compliance with the clauses and conditions stipulated
        in this contract, without prejudice to any compensation owed to the
        innocent party;
      </li>
      <li>
        Violation of the Terms of Use: the services will be terminated
        immediately;
      </li>
      <li>In the event of bankruptcy or concordat of "MY BABY BOOK".</li>
    </ol>

    <p>
      8.3. After the contract termination period, the USER's PERSONAL AREA and
      consequently all images, videos, photos, texts, or digital files
      associated with it will be permanently deleted, thus terminating this
      agreement.
    </p>
    <p>
      8.4. If at the USER's initiative this contract is terminated, they may
      request the reactivation of their PERSONAL AREA by filling out a new
      registration and subsequently sending (uploading) the images, videos,
      photos, texts, or digital files they wish to have stored.
    </p>
    <p>
      8.5. If termination has been requested by "MY BABY BOOK," they may,
      regardless of any justification, decline a new registration request from
      the USER.
    </p>
    <p>
      8.6. "MY BABY BOOK," its contractors, partners, or service providers
      cannot be held responsible for any act, fact, product, or service that is
      irregularly advertised or negotiated through the ALBUM registered in the
      USER's PERSONAL AREA. It is hereby established that the irregular use of
      the USER's PERSONAL AREA cannot be interpreted and will not lead to the
      existence of a partnership or association contract between them.
    </p>

    <h2>9. ASSIGNMENT AND TRANSFER</h2>
    <p>
      9.1. USERS may not transfer or assign this contract to third parties
      without the prior and express written consent of "MY BABY BOOK."
    </p>
    <h2>10. PAYMENT</h2>
    <p>
      10.1. The SERVICE of digital storage of images, videos, photos, texts, or
      digital files will be provided by "MY BABY BOOK," but payment and
      subscription management will be handled by "GOOGLE PLAY STORE" and "APP
      STORE (Apple)," which will ensure all security and confidentiality
      standards.
    </p>
    <p>
      10.2. The AMOUNTS related to this contract will be charged according to
      the pricing table defined unilaterally by "MY BABY BOOK," which may be
      subject to adjustments at any time, without prior notice to the USER.
    </p>
    <h2>11. CHANGES</h2>
    <p>
      11.1. "MY BABY BOOK" reserves the right to limit the use of its services,
      terminate its activities, or terminate this contract at any time, without
      prior notice to the USER, provided that clauses 7 and 8 are observed.
    </p>
    <p>
      11.2. "MY BABY BOOK" reserves the right to change any clause of this
      contract at any time, committing to communicate the change in writing
      through an email to the USER's registered email address, granting a period
      of 7 (seven) days for the USER to agree or reject the changes.
    </p>
    <p>
      11.2.1. If the USER agrees to the changes, the contract will continue
      normally, with the included changes.
    </p>
    <p>
      11.2.2. If the USER does not expressly agree with the proposed changes,
      they should send the notification as described in item 8.2 as a notice of
      contract termination. The contract will be in effect for an additional 30
      (thirty) days after the notification, during which all USER information
      will be completely erased from our database.
    </p>

    <h2>12. COPYRIGHT OF THE APPLICATION</h2>
    <p>
      12.1. "MY BABY BOOK," its contractors, partners, or service providers are
      the owners of all documents, information, and materials contained on the
      SITE and APPLICATION, except for images, videos, photos, texts, or digital
      files. Thus, the reproduction of these materials outside the SITE and
      APPLICATION is prohibited, subjecting the USER to legal penalties.
    </p>
    <p>
      12.2. The trademark, name, logo, and logotype used by "MY BABY BOOK" are
      its exclusive property and are registered with the National Institute of
      Industrial Property.
    </p>
    <p>
      12.3. This contract does not grant the USER any direct or indirect rights
      over such images, documents, information, materials, trademark, name,
      logo, and logotype owned by "MY BABY BOOK." Any form of reproduction, use,
      exploitation, assignment, lease, or sublease, as well as the use of the
      trademark, logo, images, photographs, videos, audios, animations, texts,
      without the prior and express authorization of "MY BABY BOOK," is STRICTLY
      PROHIBITED.
    </p>

    <h2>13. GENERAL PROVISIONS</h2>
    <p>
      13.1. This contract constitutes a comprehensive agreement between the
      parties, prevailing over any other verbal or written consensus concluded
      between the parties. This term is stored with the USER's "acceptance"
      within the database of "MY BABY BOOK" and will prevail over any printed
      copy made by the USER themselves.
    </p>
    <p>
      13.2. This instrument is concluded on an irrevocable and non-negotiable
      basis, except for the right of termination in the situations and in the
      manner set forth in item 8.0 of this term, with the parties individually
      liable, as required by law.
    </p>
    <p>
      13.3. All clauses that imply or may imply restrictions on the USER's
      rights have been drafted clearly, explicitly, and highlighted in order to
      comply with the provisions of Article 54, §§ 3 and 4 of the Consumer
      Protection Code.
    </p>
    <h2>14. JURISDICTION</h2>
    <p>
      14.1. To resolve disputes arising from this contract, Brazilian laws shall
      be fully applied, with the jurisdiction of the Court of Recife-PE, where
      the company is located, expressly waiving any other court, regardless of
      its jurisdiction, to settle any doubts, disputes, or litigation arising
      from this instrument.
    </p>

    <h2>15. PRIVACY POLICY</h2>
    <p>
      15.1. In addition to this present Terms of Use, the USER must also observe
      the provisions contained in the respective
      <router-link :to="to('/privacy')">Privacy Policy</router-link>
      available within the interface of the SITE and APPLICATION.
    </p>
    <h2>16. SUPPORT</h2>
    <p>
      16.1. In case of any suggestions, questions, or issues regarding the use
      of "MY BABY BOOK," the USER can contact support through the email
      <a href="mailto:atendimento@mybabybookapp.com"
        >atendimento@mybabybookapp.com</a
      >.
    </p>
    <p>These customer support services will be available whenever needed.</p>
  </div>
</template>
<script>
export default {
  name: "Terms",
  methods: {
    to(path) {
      var lang = this.$i18n.locale;
      if (lang != "en" && lang != "pt-BR") {
        return path + "/en";
      }
      return path + "/" + lang;
    },
  },
};
</script>
